<template>
    <div class="user_order">
        <el-table :row-class-name="ordertableClassName" :data="orderList" border stripe>
            <el-table-column align="center" type="index" label="序号" width="72" />
            <el-table-column width="100" align="center" prop="user.id" label="学员ID" />
            <el-table-column width="120" align="center" prop="user.nickname" label="学员昵称" />
            <el-table-column align="center" prop="addTime" label="下单时间" />
            <el-table-column header-align="center" prop="productInfo.title" label="产品名称" />
            <el-table-column align="center" label="已支付">
                <template slot-scope="scope">
                    <span>{{ scope.row.paidFee ? scope.row.paidFee / 100 : '-' }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" prop="paidTime" label="支付时间">
                <template slot-scope="scope">
                    <span>{{ scope.row.paidTime || '-' }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="状态" width="60">
                <template slot-scope="scope">
                    <span>{{ orderStatusMap[scope.row.status] }}</span>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>
<script>
import { getUserOrder } from '@/js/api/saleApi.js'
import { getProductList } from '@/js/api/shopApi.js'
import { orderStatusMap } from '@/js/config/order.js'

export default {
    props: ['activeItem'],
    data() {
        return {
            orderStatusMap,
            orderList: [],
        }
    },
    created() {
        this.getUserOrderApi()
    },
    methods: {
        ordertableClassName({ row }) {
            if (row.status === 'PAID') {
                return 'success-row'
            }
            return ''
        },
        async getUserOrderApi() {
            const params = { oppoIds: [this.activeItem.id] }
            if (this.activeItem.projectNo) params.projectNo = this.activeItem.projectNo
            const res1 = await getUserOrder(params)
            if (res1.data.length) {
                const _productNos = []
                res1.data.forEach((i) => {
                    if (!_productNos.includes(i.productNo)) {
                        _productNos.push(i.productNo)
                    }
                })
                const res2 = await getProductList({ productNos: _productNos })
                const _productMap = {}
                res2.data.forEach((item) => {
                    _productMap[item.productNo] = item
                })
                res1.data.forEach((item) => {
                    item.productInfo = _productMap[item.productNo]
                })
            }
            this.orderList = res1.data
        },
    },
}
</script>
<style lang="less" scoped>
.title {
    font-size: 14px;
    color: #333333;
    font-weight: 900;
    line-height: 28px;
    margin: 12px 0;
    span {
        color: #f00;
        font-size: 13px;
    }
}
/deep/ .el-table {
    .success-row {
        color: #67c23a;
    }
}
</style>
