<template>
    <div class="user_course">
        <div class="attend_list">
            <div class="title">
                出勤课程 <span v-if="attendData.length !== 0">[累积出勤 {{ attendData.length }} 次]</span>
            </div>
            <el-table :data="attendData" stripe border>
                <el-table-column align="center" type="index" label="序号" width="72" />
                <el-table-column align="center" prop="joinDate" label="出勤日期" width="96" />
                <el-table-column header-align="center" prop="packageTitle" label="公开课" />
                <el-table-column header-align="center" prop="courseTitle" label="课程" />
                <el-table-column label="课程评价">
                    <template slot-scope="scope">
                        <div v-if="scope.row.evaluation && scope.row.evaluation.length !== 0">
                            <div v-for="item in scope.row.evaluation" :key="item.id" :title="item.addTime">
                                {{ item.extra }}
                            </div>
                        </div>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="讲师" width="80">
                    <template slot-scope="scope">
                        <span v-if="scope.row.teacherId">
                            {{ teacherMap && teacherMap[scope.row.teacherId]['name'] }}
                        </span>
                        <span v-else>{{ scope.row.teacherId || '-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="durationText" label="出勤时长" width="90px" />
            </el-table>
        </div>
        <div class="package_list">
            <div class="title">
                报名公开课 <span v-if="packageList.length !== 0">[累积报名 {{ packageList.length }} 期]</span>
            </div>
            <el-table border stripe :data="packageList">
                <el-table-column align="center" type="index" label="序号" width="72" />
                <el-table-column align="center" prop="receiveTime" width="160" label="选课时间"></el-table-column>
                <el-table-column header-align="center" label="标题">
                    <template slot-scope="scope">
                        <span>{{ scope.row.coursePackage ? scope.row.coursePackage.title : '' }}</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>
<script>
import { getUserAttend, getUserReceivePackage } from '@/js/api/saleApi.js'
import { getEvaluationList, getCourseList, getCourseTeacher } from '@/js/api/courseApi.js'
import { getAttendDurationText } from '@/js/utils.js'

export default {
    props: ['activeItem', 'userId'],
    data() {
        return {
            teacherMap: null,
            packageList: [],

            attendData: [],
        }
    },
    created() {
        this.getUserAttendApi()
        this.getUserReceivePackageApi()
        this.getCourseTeacherApi()
    },
    methods: {
        getUserReceivePackageApi() {
            const params = {
                showPage: false,
                oppoIds: [this.activeItem.id],
            }
            if (this.activeItem.projectNo) params.projectNo = this.activeItem.projectNo
            if (this.userId) params.userIds = [this.userId]
            getUserReceivePackage(params).then((res) => {
                if (res.data && res.data.length !== 0) {
                    this.packageList = res.data.reverse()
                }
            })
        },
        getUserAttendApi() {
            const params = {
                showPage: false,
                oppoIds: [this.activeItem.id],
            }
            if (this.activeItem.projectNo) params.projectNo = this.activeItem.projectNo
            if (this.userId) params.userIds = [this.userId]
            getUserAttend(params).then((res) => {
                const _courseIds = []
                res.data.forEach((item) => {
                    item.durationText = getAttendDurationText(parseInt(item.duration))
                    if (!_courseIds.includes(item.courseId)) {
                        _courseIds.push(item.courseId)
                    }
                })
                if (_courseIds.length !== 0) {
                    const api = [getCourseList({ ids: _courseIds })]
                    if (this.userId) {
                        api.push(getEvaluationList({ userId: this.userId, _courseIds, contentNotNull: false }))
                    }
                    Promise.all(api).then((course) => {
                        const courseMap = {}
                        course[0].data.forEach((item) => {
                            courseMap[item.id] = {
                                title: item.title,
                                teacherId: item.teacherId,
                            }
                        })
                        const evaluationMap = {}
                        if (course[1]) {
                            course[1].data.forEach((item) => {
                                if (!evaluationMap[item.targetId]) {
                                    evaluationMap[item.targetId] = []
                                }
                                evaluationMap[item.targetId].push(item)
                            })
                        }
                        res.data.forEach((item) => {
                            item.courseTitle = courseMap[item.courseId]['title']
                            item.teacherId = courseMap[item.courseId]['teacherId']
                            item.evaluation = evaluationMap[item.courseId] || []
                        })
                        this.attendData = res.data
                    })
                    this.attendData = res.data
                } else {
                    this.attendData = res.data
                }
            })
        },
        getCourseTeacherApi() {
            getCourseTeacher({}).then((res) => {
                const _map = {}
                res.data.forEach((item) => {
                    _map[item.id] = item
                })
                this.teacherMap = _map
            })
        },
    },
}
</script>
<style lang="less" scoped>
.title {
    font-size: 14px;
    color: #333333;
    font-weight: 900;
    line-height: 28px;
    margin: 12px 0;
    span {
        color: #f00;
        font-size: 13px;
    }
}

.attend_list {
    margin-bottom: 20px;
}
</style>
